import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import ICONS from '../constants/icons';
import Link from './Link';

import {
  instagramFollow,
  facebookFollow,
  followButtonList,
  followButtonItem,
} from './FollowButtons.module.scss';

const FOLLOW_BUTTONS = {
  instagram: ({ socialHandle }) => (
    <Link
      href={`https://www.instagram.com/${socialHandle}`}
      className={instagramFollow}
    >
      <ICONS.instagram width="12px" height="12px" />
      &nbsp;Follow on Instagram
    </Link>
  ),
  facebook: ({ socialHandle }) => (
    <Link
      href={`https://www.facebook.com/${socialHandle}`}
      className={facebookFollow}
    >
      <ICONS.facebook width="12px" height="12px" />
      &nbsp;Follow on Facebook
    </Link>
  ),
  github: ({ socialHandle }) => (
    <Link href={`https://github.com/${socialHandle}`}>
      <img
        src={`https://img.shields.io/github/followers/${socialHandle}.svg?style=social&label=Follow on GitHub`}
        alt="Follow on GitHub"
      />
    </Link>
  ),
  twitter: ({ socialHandle }) => (
    <Link
      href={`https://twitter.com/intent/follow?screen_name=${socialHandle}`}
    >
      <img
        src={`https://img.shields.io/twitter/follow/${socialHandle}.svg?style=social&label=Follow on Twitter`}
        alt="Follow on Twitter"
      />
    </Link>
  ),
};

export default function FollowButton() {
  return (
    <StaticQuery
      query={graphql`
        query Follow {
          site {
            siteMetadata {
              follow
              socialHandles {
                instagram
                twitter
                github
                facebook
              }
            }
          }
        }
      `}
      render={({ site: { siteMetadata } }) => (
        <ul className={followButtonList}>
          {siteMetadata.follow.map((social) => {
            const FollowButton = FOLLOW_BUTTONS[social];

            return (
              <li key={social} className={followButtonItem}>
                <FollowButton
                  socialHandle={siteMetadata.socialHandles[social]}
                  aria-label={`Follow On Social Media: ${social}`}
                />
              </li>
            );
          })}
        </ul>
      )}
    />
  );
}
