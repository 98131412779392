import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import DividerButton from './DividerButton';
import Link from './Link';

export default function Comments({ title, slug = '' }) {
  return (
    <StaticQuery
      query={graphql`
        query Comments {
          site {
            siteMetadata {
              siteUrl
              socialHandles {
                twitter
              }
            }
          }
        }
      `}
      render={({ site: { siteMetadata } }) => {
        const encodedTitle = encodeURIComponent(title);
        const encodedSocialHandle = encodeURIComponent(
          siteMetadata.socialHandles.twitter
        );
        const url = siteMetadata.siteUrl + slug;

        const hrefDiscuss = `https://twitter.com/search?q=${url}`;
        const hrefShare = `https://twitter.com/share?text=I just read "${encodedTitle}" by @${encodedSocialHandle}&url=${url}`;

        return (
          <DividerButton>
            <Link aria-label="Discuss on Twitter" href={hrefDiscuss}>
              Discuss on Twitter
            </Link>
            <Link aria-label="Share on Twitter" href={hrefShare}>
              Share on Twitter
            </Link>
          </DividerButton>
        );
      }}
    />
  );
}
