import React from 'react';

import { divider, absolute } from './DividerButton.module.scss';

export default function DividerButton({ children }) {
  return (
    <div className={divider}>
      <div className={absolute}>{children}</div>
    </div>
  );
}
